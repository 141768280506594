import { useEffect, useState } from 'react'
import isServerSide from '../../../../modules/is-server-side'
import consoleLogger from '../../../../modules/console-logger'
import isStolApp from '../../../../modules/is-stolapp'
import { isObject } from 'lodash'
import hasItems from '../../../../modules/has-items'

const useUserCustomFields = (dependency) => {
  const [userData, setUserData] = useState()
  const [consoleLog] = consoleLogger('UseUserCustomFields')

  useEffect(() => {
    if (
      !isServerSide() &&
      !isStolApp('iphone|ipad') &&
      window.tp &&
      window.tp.pianoId &&
      window.tp.pianoId.loadExtendedUser
    ) {
      try {
        if (!hasItems(userData)) {
          window.tp.pianoId.loadExtendedUser({
            extendedUserLoaded: function (data) {
              if (data) {
                consoleLog('state', userData, 'UseUserData')
                consoleLog('extendedUserLoaded data', data, 'UseUserData')
                if (hasItems(data)) {
                  setUserData(data)
                }
              }
            },
            formName: 'MyAccountFields',
          })
        }
      } catch (e) {
        consoleLog('error', e, 'UseUserData')
      }
    }
  }, [dependency])

  return userData
}

export default useUserCustomFields
