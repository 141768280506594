import useFingerprintPiano from '../../../../vendor/Piano/modules/use-fingerprint-piano'
import { getStoredItem } from '../../../stored-item'
import hash from '../../../hash'

const useUnveil = () => {
  const fingerprint = ''

  const composerSpk = getStoredItem('spk', true)
  if (!fingerprint || !composerSpk) {
    return false
  }

  const date = new Date()
  const spk = fingerprint + date.getDay() + date.getHours()
  const spkCode = hash(spk)

  return false
}

export default useUnveil
