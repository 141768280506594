import RoutesConfig from '../class/RoutesConfig'
import RouteAds from '../class/RouteAds'
import RouteQuery from '../class/RouteQuery'
import RouteScreen from '../class/RouteScreen'

const routes = new RoutesConfig()

routes.addNew({
  name: 'homeIndexHtml',
  path: '/index.html',
  redirect: '/',
})
routes.addNew({
  name: 'backend',
  path: '/backend',
  redirect: 'https://re.stol.it/backend',
})
routes.addNew({
  name: 'home',
  exact: true,
  path: '/',
  screen: new RouteScreen({
    layout: 'home',
    preComponent: 'PreComponentHome',
  }),
  query: new RouteQuery({
    version: 'v5',
    modifyResponse: 'screenModifyResponseHome',
  }),
})

routes.addNew({
  name: 'article',
  path: '/artikel',
  screen: 'ScreenArticle',
  ads: new RouteAds({
    wait: true,
  }),
  query: new RouteQuery({
    useLocationPathname: true,
    modifyResponse: 'articleSPlusTargets',
  }),
})
routes.addNew({
  name: 'account',
  path: '/profil',
  topic: 'Profile',
})
routes.addNew({
  name: 'login',
  path: '/anmelden',
  topic: 'Profile',
})
routes.addNew({
  name: 'profile.restore',
  path: '/password/reset',
  topic: 'Profile',
})
routes.addNew({
  name: 'profile.new_password',
  path: '/piano/neues-kennwort',
  topic: 'Profile',
})
routes.addNew({
  name: 'profile.logout',
  path: '/abmelden',
  topic: 'Profile',
})
routes.addNew({
  name: 'newsticker',
  path: '/newsticker/:local(lokal|weltweit)?',
  screen: 'ScreenNewsFeed',
  query: true,
})
routes.addNew({
  name: 'traffic',
  path: '/verkehr/:type?/:zone?',
  screen: 'ScreenTraffic',
  query: new RouteQuery({
    useLocationPathname: true,
  }),
})
routes.addNew({
  name: 'district',
  path: '/bezirke/:district',
  exact: true,
  screen: new RouteScreen({
    layout: 'district',
  }),
  query: new RouteQuery({
    useLocationPathname: true,
    modifyResponse: 'screenModifyResponseDistrict',
  }),
})

const configDepartment = {
  exact: true,
  screen: new RouteScreen({
    layout: 'department',
  }),
  query: new RouteQuery({
    useLocationPathname: true,
  }),
}
const configSplusDepartment = {
  exact: true,
  screen: new RouteScreen({
    layout: 'departmentSplus',
  }),
  query: new RouteQuery({
    useLocationPathname: true,
  }),
}

routes.addNew({
  name: 'department.splus',
  path: '/rubrik/plus',
  ...configSplusDepartment,
})
routes.addNew({
  name: 'department',
  path: '/rubrik/:department/:local(lokal|weltweit)?',
  ...configDepartment,
})
routes.addNew({
  name: 'department.category',
  path: '/rubrik/:department/:category/:local(lokal|weltweit)?',
  ...configDepartment,
})
const configOnTour = {
  screen: 'ScreenOnTour',
  query: new RouteQuery({
    useLocationPathname: true,
  }),
}
routes.addNew({
  name: 'ontour',
  path: '/ontours',
  ...configOnTour,
})
routes.addNew({
  name: 'ontour.detail',
  path: '/ontour/:date/:title',
  ...configOnTour,
})
routes.addNew({
  name: 'obituary',
  path: '/trauer',
  screen: 'ScreenObituary',
  query: new RouteQuery({
    useLocationPathname: true,
  }),
})
routes.addNew({
  name: 'bookmarks',
  path: '/lesezeichen',
  topic: 'Bookmark',
})
const configWeather = {
  exact: true,
  topic: 'Weather',
}
routes.addNew({
  name: 'weather',
  path: '/wetter',
  ...configWeather,
})
routes.addNew({
  name: 'weather.rainfall_radar',
  path: '/wetter/niederschlagsradar',
  ...configWeather,
})
routes.addNew({
  name: 'weather.mountain',
  path: '/wetter/bergwetter',
  ...configWeather,
})
routes.addNew({
  name: 'weather.avalanche',
  path: '/wetter/lawinenreport',
  ...configWeather,
})

routes.addNew({
  name: 'search',
  path: '/suche/:sortBy(datum|relevanz)?/:searchTerm*',
  screen: 'ScreenSearch',
  query: new RouteQuery({
    customApiPath: 'customApiPathSearch',
    version: 'v2',
  }),
})
routes.addNew({
  name: 'userGeneratedContent',
  path: '/p/reporter',
  topic: 'UserGeneratedContent',
})
routes.addNew({
  name: 'special.jobwidget',
  path: '/stellenanzeigen',
  topic: 'JobWidgetPage',
})
routes.addNew({
  name: 'video',
  path: '/video/:category/:title?',

  screen: 'ScreenVideoDetail',

  query: new RouteQuery({
    useLocationPathname: true,
    modifyResponse: 'articleSPlusTargets',
  }),
})
routes.addNew({
  name: 'videos',
  path: '/videos/:local(lokal|weltweit)?',
  topic: 'Video',
})
routes.addNew({
  name: 'dolomiten',
  path: '/dolomiten',
  screen: 'ScreenPaper',
  query: new RouteQuery({
    useLocationPathname: true,
  }),
})
routes.addNew({
  name: 'nearMe',
  path: '/in-meiner-naehe/:latitude?/:longitude?/:radius?',
  api: '/aroundme/:latitude?/:longitude?/:radius?',
  topic: 'NearMe',
})
routes.addNew({
  name: 'error.page',
  exact: true,
  topic: 'NotFoundError',
})
routes.addNew({
  name: 'tag',
  path: '/tag/:slug+',
  exact: true,
  api: '/tag/:slug',
  screen: new RouteScreen({
    layout: 'tag',
  }),
  query: new RouteQuery({
    useLocationPathname: true,
    modifyResponse: 'screenModifyResponseTag',
  }),
})
routes.addNew({
  name: 'special.geolocationwall',
  path: '/p/geolocationwall/',
  topic: 'GeolocationWall',
  showLayout: false
})
routes.addNew({
  name: 'special',
  path: '/spezial/:slug',
  exact: true,
  api: '/spezial/:slug',
  topic: 'Topic',
})


routes.addNew({
  name: 'settings',
  path: '/p/dlabsettings',
  topic: 'DlabSettings',
})
routes.addNew({
  name: 'special.council.allresults',
  path: '/p/gemeinderatswahlen-einegemeinde',
  topic: 'CouncilElection',
  exact: true,
})
routes.addNew({
  name: 'special.council.mayors',
  exact: true,
  path: '/p/gemeinderatswahlen-allebuergermeister',
  topic: 'CouncilElection',
})
routes.addNew({
  name: 'special.faq',
  path: '/p/faq/',
  topic: 'Faq',
})



routes.addNew({
  name: 'special.page',
  path: '/p/:slug',
  screen: 'ScreenPage',
  query: new RouteQuery({
    useLocationPathname: true,
  }),
})


routes.addNew({
  name: 'special.topic',
  path: '/:slug',
  exact: true,
  api: '/spezial/:slug',
  topic: 'Topic',
})




routes.addNew({
  name: 'everything.else',
  path: '*',
  screen: 'ScreenPage',
  query: new RouteQuery({
    useLocationPathname: true,
    modifyResponse: 'articleSPlusTargets',
  }),
})
routes.addNew({
  name: 'not.found',
  exact: true,
  topic: 'NotFoundError',
})
routes.addNew({
  name: 'menu',
  query: new RouteQuery({
    version: 'v2',
  }),
})

export default routes
